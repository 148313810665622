<template>
  <div class="container">
    <Hero :data="hero" />

    <div class="content">
      <div class="row">
        <div class="col-xs-12 col-sm-4">
          <div class="intro">Vi har</div>
          <h3 class="intro-header">Mer enn 20 års erfaring i spillbransjen</h3>
          <div class="intro-text">
            Etter å ha jobbet med en rekke nummer 1-spill i kjente selskaper som Funcom og Artplant, har vi fått en unik innsikt i kunst, teknologi, brukeropplevelser og engasjement.
          </div>
        </div>
        <div class="col-xs-12 col-sm-4">
          <div class="intro">Vi er</div>
          <h3 class="intro-header">Lekende teknologi eksperter</h3>
          <div class="intro-text">
            Samfunnets utvikling stiller nye krav til brukeropplevelser. Dette kan vi til fingerspissene. Vi løfter spilltankegangen til nye samfunnsarenaer.
          </div>
        </div>
        <div class="col-xs-12 col-sm-4">
          <div class="intro">Vi tilbyr</div>
          <h3 class="intro-header">Å skape gode digitale løsninger</h3>
          <div class="intro-text">
            Vi leverer teknologi og opplevelser på tvers av plattformer til norsk næringsliv. Vi har en vitenskapelig tilnærming til moro.
          </div>
        </div>
      </div>
    </div>
    <div class="spacer"></div>
    <div class="content">
      <h4>Tjenester</h4>
      <h2>Hva kan vi gjøre for deg</h2>
      <div class="row" style="text-align: center">
        <div class="col-xs-6 col-sm-3" style="margin-bottom: 40px">
          <img src="@/assets/images/gamification.png" alt="Gamification" style="margin: auto" />
          <h4 style="padding: 20px 0 10px 0">Gamification</h4>
          Det meste i arbeidslivet kan, og bør, gjøres mer lekent. Vi utvikler avanserte Gamification-systemer, som spiller på lag med din egen kultur og innsikt. Er din bedrift klar?
        </div>
        <div class="col-xs-6 col-sm-3" style="margin-bottom: 40px">
          <img src="@/assets/images/digital_læring.png" alt="Digital læring" style="margin: auto" />
          <h4 style="padding: 20px 0 10px 0">Digital læring</h4>
          Læringseffekt oppnås best når det seriøse blandes med noe engasjerende og morsomt. Vi er beviselige eksperter innen digital læring, til bruk for ansatte, elever eller samfunnet.
        </div>
        <div class="col-xs-6 col-sm-3" style="margin-bottom: 40px">
          <img src="@/assets/images/apper.png" alt="Apper" style="margin: auto" />
          <h4 style="padding: 20px 0 10px 0">Apper</h4>
          Vi kan apper! Fra første ide, frem til utvikling, drift, analyse og tilpasning. Prosjektene spenner fra løsninger for Rikstoto, Pensjonsfond og varehandelen, til AR og VR prosjekter.
        </div>
        <div class="col-xs-6 col-sm-3" style="margin-bottom: 40px">
          <img src="@/assets/images/ekspertbistand.png" alt="Ekspertbistand" style="margin: auto" />
          <h4 style="padding: 20px 0 10px 0">Ekspertbistand</h4>
          Vi hjelper mange av Norges største bedrifter og har en rekke eksperter innen programmering, brukeropplevelser (UX/UI), serverteknologi, webutvikling og digital kunst.
        </div>
      </div>
    </div>

    <div class="hightlighter">
      <div class="content">
        <h2>Lurer du på hvordan skreddersydde gamification-løsninger kan styrke din bedrift eller organisasjon, eller vil du se hva vi kan trylle frem?</h2>
        <router-link to="/kontakt" class="button blue">
          Kontakt oss
        </router-link>
      </div>
    </div>
    <div class="content">
      <h2>Våre kunder</h2>
      <div class="row">
        <img src="@/assets/images/kunder.png" alt="Våre kunder" style="margin: auto" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    hero: {
      image: "home_hero.png",
      header: "Gamification",
      tag: "med målbar effekt"
    }
  }),
  components: {
    Hero: () => import("@/views/components/Hero.vue")
  }
};
</script>

<style lang="scss" scoped>
.intro {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 2px;
  color: #999;
}

.intro-header {
  text-transform: uppercase;
  padding-right: 30px;
}

.intro-text {
  padding-right: 30px;
  margin-bottom: 40px;
  @media only screen and (min-width: 768px) {
    margin-bottom: 0px;
  }
}
.spacer {
  height: 40px;
  @media only screen and (min-width: 768px) {
    height: 100px;
  }
}
.portfolio-element {
  padding: 0;
}
</style>
